import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import Scheduler from '../dashboard/Scheduler'
import SchedulerWeekView from '../dashboard/SchedulerWeekView'
import BookingCalendar from '../booking/BookingCalendar'

const lanesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Scheduler',
    path: '/scheduler',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SchedulerPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='all-view'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | All View'}></DocumentTitle>
              <PageTitle breadcrumbs={lanesBreadcrumbs}>Scheduler</PageTitle>
              <Scheduler />
            </>
          }
        />
        <Route
          path='week-view'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Week View'}></DocumentTitle>
              <PageTitle breadcrumbs={lanesBreadcrumbs}>Scheduler</PageTitle>
              <SchedulerWeekView />
            </>
          }
        />
        <Route
          path='booking-view'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Booking View'}></DocumentTitle>
              <PageTitle breadcrumbs={lanesBreadcrumbs}>Booking</PageTitle>
              <BookingCalendar />
            </>
          }
        />
        <Route index element={<Navigate to='/scheduler/all-view' />} />
      </Route>
    </Routes>
  )
}

export default SchedulerPage
