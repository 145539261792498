import { lazy, FC, Suspense, useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import SupportPage from '../modules/support/SupportPage'
import { meApi } from '../modules/auth/core/_requests'
import DashboardPage from '../modules/dashboard/DashboardPage'
import BranchesPage from '../modules/branches/BranchesPage'
import ClubsPage from '../modules/clubs/ClubsPage'
import LanesPage from '../modules/lanes/LanesPage'
import UsersPage from '../modules/users/UsersPage'
import PlacesPage from '../modules/places/PlacesPage'
import SchedulerPage from '../modules/scheduler/SchedulerPage'
import MaintenancePage from '../modules/dashboard/MaintenancePage'
import SettingsPage from '../modules/settings/SettingsPage'
import AreasPage from '../modules/areas/AreasPage'
import BookingsPage from '../modules/booking/BookingsPage'
import { useAuth } from '../modules/auth/core/AuthProvider'
import { LayoutSplashScreen } from '../../_metronic/layout/core'

const PrivateRoutes = () => {
  const { token, hasValidRole, loggedIn } = useAuth()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const checkAuth = async () => {
      if (!token) {
        setLoading(false)
        return
      }
      try {
        await meApi()
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    checkAuth()
  }, [token])

  if (!loggedIn) {
    return <Navigate to='/auth' />
  }

  if (loading) {
    return <LayoutSplashScreen />
  }

  if (loggedIn && hasValidRole === 2) {
    return (
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route
            path='dashboard/*'
            element={
              <SuspensedView>
                <DashboardPage />
              </SuspensedView>
            }
          />
          <Route
            path='bookings/*'
            element={
              <SuspensedView>
                <BookingsPage />
              </SuspensedView>
            }
          />
          <Route
            path='under-construction/*'
            element={
              <SuspensedView>
                <MaintenancePage />
              </SuspensedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    )
  } else if (loggedIn && hasValidRole === 1) {
    return (
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route
            path='dashboard/*'
            element={
              <SuspensedView>
                <DashboardPage />
              </SuspensedView>
            }
          />
          <Route
            path='users/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          <Route
            path='branches/*'
            element={
              <SuspensedView>
                <BranchesPage />
              </SuspensedView>
            }
          />
          <Route
            path='bookings/*'
            element={
              <SuspensedView>
                <BookingsPage />
              </SuspensedView>
            }
          />
          <Route
            path='clubs/*'
            element={
              <SuspensedView>
                <ClubsPage />
              </SuspensedView>
            }
          />
          <Route
            path='lanes/*'
            element={
              <SuspensedView>
                <LanesPage />
              </SuspensedView>
            }
          />
          <Route
            path='places/*'
            element={
              <SuspensedView>
                <PlacesPage />
              </SuspensedView>
            }
          />
          <Route
            path='scheduler/*'
            element={
              <SuspensedView>
                <SchedulerPage />
              </SuspensedView>
            }
          />
          <Route
            path='areas/*'
            element={
              <SuspensedView>
                <AreasPage />
              </SuspensedView>
            }
          />
          <Route
            path='under-construction/*'
            element={
              <SuspensedView>
                <MaintenancePage />
              </SuspensedView>
            }
          />
          <Route
            path='settings/*'
            element={
              <SuspensedView>
                <SettingsPage />
              </SuspensedView>
            }
          />
          <Route
            path='support/*'
            element={
              <SuspensedView>
                <SupportPage />
              </SuspensedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    )
  } else {
    return (
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route
            path='dashboard/*'
            element={
              <SuspensedView>
                <DashboardPage />
              </SuspensedView>
            }
          />
          <Route path='*' element={<Navigate to='/error/mustafa' />} />
        </Route>
      </Routes>
    )
  }
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
