import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import axios from 'axios'
import './personal_availability.scss'
import TrainerAvailabilityEditModal from './components/TrainerAvailabilityEditModal'
import TrainerAvailabilityAddModal from './components/TrainerAvailabilityAddModal'
import { useAuth } from '../auth/core/AuthProvider'

const PersonalAvailabilityCalendar = () => {
  const { hasValidRole } = useAuth()
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  const [events, setEvents] = useState([] as any)
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [selectedDates, setSelectedDates] = useState<any>(null)
  const roleColors: { [key: string]: string } = {
    PT: '#004f44',
    PHY: '#ff4f40',
    COA: '#f5a623 ',
  }
  let apiUrl = 'trainer/availibility_calendars'
  if (hasValidRole === 1) {
    apiUrl = 'admin/availibility_calendars'
  }
  const fetchAvailabilityData = async () => {
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      if (response.data['hydra:member']) {
        const rolesMap: { [key: string]: string } = {
          ROLE_PERSONAL_TRAINER: 'PT',
          ROLE_PHYSIO: 'PHY',
          ROLE_COACH: 'COA',
        }
        let events = response.data['hydra:member'].map((event: any) => {
          const userRoles = (Object.values(event.user.roles) as string[])
            .filter((role: string) => rolesMap[role])
            .map((role: string) => rolesMap[role])
            .join(' - ')
          return {
            id: event.id,
            days: event.days,
            start: event.startsAt,
            end: event.endsAt,
            startTime: event.startTime,
            endTime: event.endTime,
            userName: event.user.name,
            userRole: userRoles,
          }
        })

        const formattedEvents = events.flatMap((event: any) => {
          const startDate = new Date(event.start)
          const endDate = new Date(event.end)
          const eventsForDays = []

          for (
            let currentDate = new Date(startDate);
            currentDate <= endDate;
            currentDate.setDate(currentDate.getDate() + 1)
          ) {
            if (event.days.includes(currentDate.getDay().toString())) {
              let startDatetime = new Date(currentDate)
              let endDatetime = new Date(currentDate)

              const startT = new Date(event.startTime)
              const endT = new Date(event.endTime)
              startDatetime.setHours(startT.getUTCHours(), startT.getUTCMinutes())
              endDatetime.setHours(endT.getUTCHours(), endT.getUTCMinutes())
              eventsForDays.push({
                title: event.userName + ' (' + event.userRole + ')',
                start: new Date(startDatetime),
                end: new Date(endDatetime),
                id: event.id,
                backgroundColor: roleColors[event.userRole],
              })
            }
          }
          return eventsForDays
        })

        setEvents(formattedEvents)
      }
    } catch (error) {
      console.error('Error fetching availability data:', error)
    }
  }

  useEffect(() => {
    fetchAvailabilityData()
  }, [])

  const handleEventClick = (clickInfo: any) => {
    setItemIdForUpdate(clickInfo.event.id)
    setSelectedEvent(clickInfo.event.id)
    setShowEditModal(true)
  }
  const handleDateSelect = (selectInfo: any) => {
    const selectedDates = {
      start: selectInfo.startStr,
      end: selectInfo.endStr,
    }
    setSelectedDates(selectedDates)
    setShowAddModal(true)
  }

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        initialView='listWeek'
        events={events}
        selectable={true}
        select={handleDateSelect}
        editable={false}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        }}
        allDaySlot={false}
        slotMinTime='06:00:00'
        slotMaxTime='23:00:00'
        slotDuration='01:00:00'
        slotLabelInterval='01:00:00'
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: false,
          hour12: false,
        }}
        dayHeaderFormat={{
          weekday: 'long',
        }}
        height='auto'
        eventClick={handleEventClick}
      />
      {hasValidRole === 1 ? (
        <div className='calendar-legend'>
          <div className='legend-item'>
            <span className='color-box' style={{ backgroundColor: roleColors.PT }}></span>
            <span>Personal Trainer (PT)</span>
          </div>
          <div className='legend-item'>
            <span className='color-box' style={{ backgroundColor: roleColors.PHY }}></span>
            <span>Physiotherapist (PHY)</span>
          </div>
          <div className='legend-item'>
            <span className='color-box' style={{ backgroundColor: roleColors.COA }}></span>
            <span>Coach (COA)</span>
          </div>
        </div>
      ) : null}
      {showEditModal && (
        <TrainerAvailabilityEditModal
          show={showEditModal}
          itemIdForUpdate={itemIdForUpdate}
          onClose={() => {
            setItemIdForUpdate('')
            setShowEditModal(false)
          }}
          refresh={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      )}

      {showAddModal && (
        <TrainerAvailabilityAddModal
          show={showAddModal}
          selectedDates={selectedDates}
          onClose={() => setShowAddModal(false)}
          refresh={() => fetchAvailabilityData()}
        />
      )}
    </>
  )
}

export default PersonalAvailabilityCalendar
