import React from 'react'
import DocumentTitle from 'react-document-title'

function SupportPage() {
  return (
    <div>
      <DocumentTitle title={process.env.REACT_APP_NAME + ' | Support'}></DocumentTitle>
      <iframe
        src='https://followup.ai-websolutions.com/watch/be10670c764264cee1b99616d6bf22f1'
        style={{
          width: '100%',
          height: 'calc(100vh - 200px)',
        }}
      />
    </div>
  )
}

export default SupportPage
