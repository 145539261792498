import React, { SyntheticEvent, useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import ResultViewer from '../../../utils/ResultViewer'
import AreaEditModal from './AreaEditModal'

function AreasList() {
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  const convertToTime = (datetime: string) => {
    const date = new Date(datetime)
    const hour = date.getUTCHours()
    const minutes = date.getUTCMinutes()
    return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
  }

  let areaHeader = [
    { index: 1, type: 'field', key: 'id', title: 'ID', className: 'min-w-125px' },
    { index: 2, type: 'field', key: 'title', title: 'Name', className: 'min-w-125px' },
    {
      index: 3,
      type: 'object',
      key: 'areaType',
      subKey: 'name',
      title: 'Type',
      className: 'min-w-125px',
    },
    {
      index: 4,
      type: 'object',
      key: 'branch',
      subKey: 'title',
      title: 'Branch',
      className: 'min-w-125px',
    },
    {
      index: 5,
      type: 'field',
      key: 'availableTimeStart',
      title: 'Available Time Start',
      className: 'min-w-125px',
      postProcess: convertToTime,
    },
    {
      index: 6,
      type: 'field',
      key: 'availableTimeEnd',
      title: 'Available Time End',
      className: 'min-w-125px',
      postProcess: convertToTime,
    },
    {
      index: 7,
      type: 'field',
      key: 'reservationTimeInterval',
      title: 'Max Reservation Time (Minute)',
      className: 'min-w-125px',
    },
    { index: 5, type: 'field', key: 'actions', title: 'Actions', className: 'min-w-125px' },
  ]
  console.log('itemIdForUpdate:', itemIdForUpdate)

  return (
    <>
      <ResultViewer
        title={'Areas'}
        dataUrl={'/admin/areas'}
        editCallback={(id: any, refresh: Function) => {
          console.log('Edit callback:', id)
          setRefreshFunction(() => refresh)
          if (id !== undefined && id !== '') {
            setItemIdForUpdate(id)
          }
        }}
        deleteCallback={(id: any, refresh: Function) => {
          Swal.fire({
            title: 'Warning',
            html: 'Are you want to delete this area?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-primary',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete('admin/areas/' + id).then(() => {
                refresh()
              })
            }
          })
        }}
        actionItems={[
          {
            text: 'Add New Area',
            class: 'btn btn-sm btn-primary',
            icon: 'fa fa-golf-ball',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              if (itemIdForUpdate !== undefined) {
                setItemIdForUpdate('add')
              }
              setRefreshFunction(() => refresh)
            },
          },
        ]}
        header={areaHeader}
      />
      {itemIdForUpdate !== '' ? (
        <AreaEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onClose={() => {
            setItemIdForUpdate('')
          }}
          refresh={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default AreasList
