import React from 'react'
import DocumentTitle from 'react-document-title'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DummyDashboard from './DummyDashboard'

const DashboardPage = () => {
  const dashboardBreadcrumb: Array<PageLink> = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='admin'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Dashboard'}></DocumentTitle>

              <DummyDashboard />
            </>
          }
        />
        <Route index element={<Navigate to='/dashboard/admin' />} />
      </Route>
    </Routes>
  )
}

export default DashboardPage
