import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import {
  fetchUsersList,
  fetchBranchList,
  fetchTrainers,
  fetchAreasList,
  fetchUsersForTrainers,
  fetchAreasForTrainers,
} from '../../../utils/SelectListMethods'
import { hoursOptions } from '../../../utils/Constants'
import { useAuth } from '../../auth/core/AuthProvider'

interface BookingEditModalProps {
  itemIdForUpdate: string
  show: boolean
  onClose: () => void
  refresh: Function
}

class BookingRequest {
  trainer?: string
  user?: string
  area?: string
  date?: string
  startTime?: string
  endTime?: string
  status?: string
}

const BookingRequestEditModal: React.FC<BookingEditModalProps> = ({
  itemIdForUpdate,
  show,
  onClose,
  refresh,
}) => {
  const [formData, setFormData] = useState<BookingRequest>({
    trainer: '',
    user: '',
    area: '',
    date: '',
    startTime: '',
    endTime: '',
    status: '',
  })
  const { hasValidRole, user } = useAuth()
  const [trainerOptions, setTrainersOptions] = useState<any[]>([])
  const [usersOptions, setUsersOptions] = useState<any[]>([])
  const [areaOptions, setAreaOptions] = useState<any[]>([])
  const [date, setDate] = useState(new Date())
  const [startTime, setStartTime] = useState(new Date())
  const [endTime, setEndTime] = useState(new Date())
  const bookingStatusOptions = [
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Rejected', label: 'Rejected' },
  ]
  let bookingUrl = '/admin/bookings'
  if (hasValidRole == 2) {
    bookingUrl = '/trainer/bookings'
  }
  const fetchSingleData = async () => {
    if (itemIdForUpdate !== 'add') {
      axios
        .get(`${bookingUrl}/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        })
        .then((response) => {
          setFormData({
            trainer: response.data.trainer['@id'] || '',
            user: response.data.user['@id'] || '',
            area: response.data.area['@id'] || '',
            date: new Date(response.data.date).toISOString().substring(0, 10),
            startTime: new Date(response.data.startTime).toISOString().substring(11, 16),
            endTime: new Date(response.data.endTime).toISOString().substring(11, 16),
            status: response.data.status,
          })
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    } else {
      setFormData({
        trainer: '',
        user: '',
        area: '',
        date: '',
        startTime: '',
        endTime: '',
        status: '',
      })
    }
  }

  const updateData = async () => {
    const currentDate = new Date().toISOString().split('T')[0]

    const editedStartTime = new Date(`${currentDate}T${formData.startTime}:00Z`).toISOString()
    const editedEndTime = new Date(`${currentDate}T${formData.endTime}:00Z`).toISOString()

    const updatedData = {
      ...formData,
      startTime: editedStartTime,
      endTime: editedEndTime,
    }
    if (hasValidRole == 2) delete updatedData.trainer
    axios
      .put(`${bookingUrl}/${itemIdForUpdate}`, updatedData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        console.log('Data updated:', response.data)
        onClose()
        refresh(true)
      })
      .catch((error) => {
        console.error('Error updating data:', error)
      })
  }

  const createData = async () => {
    const currentDate = new Date().toISOString().split('T')[0]

    const editedStartTime = new Date(`${currentDate}T${formData.startTime}:00Z`).toISOString()
    const editedEndTime = new Date(`${currentDate}T${formData.endTime}:00Z`).toISOString()
    const newData = {
      ...formData,
      startTime: editedStartTime,
      endTime: editedEndTime,
    }
    if (hasValidRole == 2) delete newData.trainer
    axios
      .post(`${bookingUrl}`, newData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onClose()
        refresh(true)
        setFormData({
          trainer: '',
          user: '',
          area: '',
          date: '',
          startTime: '',
          endTime: '',
          status: '',
        })
      })
      .catch((error) => {
        console.error('Error creating data:', error)
      })
  }

  const getUsersOptions = async () => {
    fetchUsersList()
      .then((response) => {
        setUsersOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching user options:', error)
      })
  }

  const getAreaOptions = async () => {
    fetchAreasList()
      .then((response) => {
        setAreaOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching area list:', error)
      })
  }

  const getTrainersOptions = async () => {
    fetchTrainers()
      .then((response) => {
        setTrainersOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching branch list:', error)
      })
  }

  const getUsersOptionsForTrainers = async () => {
    fetchUsersForTrainers()
      .then((response) => {
        setUsersOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching branch list:', error)
      })
  }

  const getAreasOptionsForTrainers = async () => {
    fetchAreasForTrainers()
      .then((response) => {
        setAreaOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching branch list:', error)
      })
  }

  useEffect(() => {
    if (itemIdForUpdate !== 'add') {
      fetchSingleData()
      if (hasValidRole === 2) {
        getUsersOptionsForTrainers()
        getAreasOptionsForTrainers()
      }
      if (hasValidRole === 1) {
        getUsersOptions()
        getTrainersOptions()
        getAreaOptions()
      }
    }
  }, [itemIdForUpdate])

  useEffect(() => {
    if (hasValidRole === 2) {
      getUsersOptionsForTrainers()
      getAreasOptionsForTrainers()
    }
    if (hasValidRole === 1) {
      getUsersOptions()
      getTrainersOptions()
      getAreaOptions()
    }
  }, [])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
    console.log('Form Data:', formData)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (itemIdForUpdate === 'add') {
      createData()
    } else {
      updateData()
    }
  }

  return (
    <Modal show={show} onHide={onClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{itemIdForUpdate === 'add' ? 'Add Booking' : 'Edit Booking'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {hasValidRole === 1 ? (
            <Form.Group controlId='formTrainer'>
              <Form.Label>Trainer</Form.Label>
              <Form.Select name='trainer' value={formData.trainer} onChange={handleChange} required>
                <option value=''>Select Trainer</option>
                {trainerOptions.map((trainer) => (
                  <option key={trainer.value} value={trainer.value}>
                    {trainer.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          ) : null}
          <Form.Group controlId='formuser'>
            <Form.Label>User</Form.Label>
            <Form.Select name='user' value={formData.user} onChange={handleChange} required>
              <option value=''>Select User</option>
              {usersOptions.map((user) => (
                <option key={user.value} value={user.value}>
                  {user.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formArea'>
            <Form.Label>Area</Form.Label>
            <Form.Select name='area' value={formData.area} onChange={handleChange} required>
              <option value=''>Select Area</option>
              {areaOptions.map((area) => (
                <option key={area.value} value={area.value}>
                  {area.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formDate'>
            <Form.Label>Date</Form.Label>
            <Form.Control
              type='date'
              name='date'
              value={formData.date}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='formStartTime'>
            <Form.Label>Start Time</Form.Label>
            <Form.Select
              name='startTime'
              value={formData.startTime}
              onChange={handleChange}
              required
            >
              <option value=''>Select Time</option>
              {hoursOptions.map((hour) => (
                <option key={hour.value} value={hour.value}>
                  {hour.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formEndTime'>
            <Form.Label>End Time</Form.Label>
            <Form.Select name='endTime' value={formData.endTime} onChange={handleChange} required>
              <option value=''>Select Time</option>
              {hoursOptions.map((hour) => (
                <option key={hour.value} value={hour.value}>
                  {hour.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formStatus'>
            <Form.Label>Status</Form.Label>
            <Form.Select name='status' value={formData.status} onChange={handleChange} required>
              <option value=''>Select Status</option>
              {bookingStatusOptions.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <div className='text-end mt-3'>
            <Button variant='secondary' onClick={onClose} className='me-2'>
              Cancel
            </Button>
            <Button type='submit' variant='primary'>
              Save changes
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default BookingRequestEditModal
