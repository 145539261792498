import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { userRoles, userStatus } from '../../utils/ConstVariables'

type UserEditModalProps = {
  onHide: () => void
  show: boolean
  itemIdForUpdate?: string
  refreshFunction?: () => void
}

class User {
  name?: string
  email?: string
  roles: Array<string> = []
  status?: string
  profile_photo?: string
  address?: string
}

const UserEditModal: React.FC<UserEditModalProps> = ({
  onHide,
  show,
  itemIdForUpdate,
  refreshFunction,
}) => {
  const [formData, setFormData] = useState<User>({
    name: '',
    email: '',
    status: '',
    roles: [],
    profile_photo: '',
    address: '',
  })

  const [originalData, setOriginalData] = useState<User | null>(null)

  const roles = userRoles
  const statusOptions = userStatus

  const fetchSingleData = async () => {
    if (itemIdForUpdate !== 'new') {
      axios
        .get(`/admin/users/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
          },
        })
        .then((response) => {
          const data = {
            name: response.data.name,
            email: response.data.email,
            status: response.data.status,
            roles: response.data.roles,
            profile_photo: response.data.profile_photo,
            address: response.data.address,
          }
          setFormData(data)
          setOriginalData(data)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    } else {
      setFormData({
        name: '',
        email: '',
        status: '',
        roles: [],
        profile_photo: '',
        address: '',
      })
      setOriginalData(null)
    }
  }

  const getChangedFields = (original: any, updated: any) => {
    let changes: any = {}
    for (const key in updated) {
      if (updated[key] !== original[key]) {
        changes[key] = updated[key]
      }
    }
    return changes
  }

  const updateData = async () => {
    if (!originalData) return

    const changes = getChangedFields(originalData, formData)

    if (Object.keys(changes).length === 0) {
      console.log('No changes detected')
      return
    }

    axios
      .patch(`/admin/users/${itemIdForUpdate}`, changes, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        console.log('Data updated:', response.data)
        onHide()
        refreshFunction && refreshFunction()
      })
      .catch((error) => {
        console.error('Error updating data:', error)
      })
  }

  const createData = async () => {
    axios
      .post(`/admin/users`, formData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onHide()
        refreshFunction && refreshFunction()
        setFormData({
          name: '',
          email: '',
          status: '',
          roles: [],
          profile_photo: '',
          address: '',
        })
      })
      .catch((error) => {
        console.error('Error creating data:', error)
      })
  }

  useEffect(() => {
    if (itemIdForUpdate !== 'new') {
      fetchSingleData()
    }
  }, [itemIdForUpdate])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleMultipleCheckSelectChange = (event: any) => {
    const { value, checked } = event.target
    const rolesArray: string[] = Array.isArray(formData.roles)
      ? formData.roles
      : (Object.values(formData.roles) as string[])
    const newRoles: string[] = checked
      ? [...rolesArray, value]
      : rolesArray.filter((role: string) => role !== value)
    setFormData({ ...formData, roles: newRoles })
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (itemIdForUpdate === 'new') {
      createData()
    } else if (itemIdForUpdate !== 'new' && itemIdForUpdate !== '') {
      updateData()
    }
  }

  return (
    <Modal show={show} onHide={onHide} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{itemIdForUpdate === 'new' ? 'Add User' : 'Edit User'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='formUserName'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='formUserEmail'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId='formUserRoles'>
            <Form.Label>Roles</Form.Label>
            {roles.map((role) => (
              <Form.Check
                type='checkbox'
                key={role.value}
                label={role.label}
                value={role.value}
                checked={formData.roles?.includes(role.value)}
                onChange={handleMultipleCheckSelectChange}
                style={{ marginBottom: '10px' }}
              />
            ))}
          </Form.Group>
          <Form.Group controlId='formUserStatus'>
            <Form.Label>Status</Form.Label>
            <Form.Select
              name='status'
              value={formData.status}
              onChange={handleSelectChange}
              required
            >
              <option value=''>Select status</option>
              {statusOptions.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <div className='text-end mt-3'>
            <Button variant='secondary' onClick={onHide} className='me-2'>
              Cancel
            </Button>
            <Button type='submit' variant='primary'>
              Save changes
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
export default UserEditModal
