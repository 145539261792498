import React, { SyntheticEvent, useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import ResultViewer from '../../../utils/ResultViewer'
import BookingRequestEditModal from './BookingRequestEditModal'
import { useAuth } from '../../auth/core/AuthProvider'

function BookingRequestList() {
  const { user, loggedIn, hasValidRole } = useAuth()
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  let bookingHeader = [
    { index: 1, type: 'field', key: 'id', title: 'ID', className: 'min-w-125px' },
    {
      index: 2,
      type: 'object',
      key: 'trainer',
      subKey: 'name',
      title: 'Personal',
      className: 'min-w-125px',
    },

    {
      index: 3,
      type: 'object',
      key: 'user',
      subKey: 'name',
      title: 'User',
      className: 'min-w-125px',
    },
    {
      index: 4,
      type: 'object',
      key: 'area',
      title: 'Area',
      subKey: 'title',
      className: 'min-w-125px',
    },
    {
      index: 5,
      type: 'field',
      key: 'date',
      title: 'Date',
      postProcess: (datetime: string) => {
        if (datetime) {
          const date = new Date(datetime)
          return date.toLocaleDateString()
        } else {
          return 'N/A'
        }
      },
      className: 'min-w-125px',
    },
    {
      index: 6,
      type: 'field',
      key: 'startTime',
      title: 'Start Time',
      postProcess: (datetime: string) => {
        if (!datetime) {
          return 'N/A'
        } else {
          const date = new Date(datetime)
          const hour = date.getUTCHours()
          const minutes = date.getUTCMinutes()
          return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        }
      },
      className: 'min-w-125px',
    },
    {
      index: 7,
      type: 'field',
      key: 'endTime',
      title: 'End Time',
      postProcess: (datetime: string) => {
        if (!datetime) {
          return 'N/A'
        } else {
          const date = new Date(datetime)
          const hour = date.getUTCHours()
          const minutes = date.getUTCMinutes()
          return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        }
      },
      className: 'min-w-125px',
    },
    {
      index: 8,
      type: 'field',
      key: 'status',
      title: 'Status',
      className: 'min-w-125px',
      postProcess: (status: string) => {
        return status === 'Approved' ? (
          <span className='badge badge-success'>Approved</span>
        ) : status === 'Rejected' ? (
          <span className='badge badge-danger'>Rejected</span>
        ) : (
          <span className='badge badge-warning'>Pending</span>
        )
      },
    },
    {
      index: 9,
      type: 'field',
      key: 'actions',
      title: 'Actions',
      className: 'min-w-125px',
    },
  ]

  let bookingUrl = '/admin/bookings'
  if (hasValidRole === 2) {
    bookingUrl = '/trainer/bookings'
    bookingHeader = bookingHeader.filter((header) => ![2].includes(header.index))
  }

  return (
    <>
      <ResultViewer
        title={'Bookings'}
        dataUrl={bookingUrl}
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          if (id !== undefined && id !== '') {
            setItemIdForUpdate(id)
          }
        }}
        deleteCallback={(id: any, refresh: Function) => {
          Swal.fire({
            title: 'Warning',
            html: 'Are you want to delete this booking?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-primary',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete('admin/bookings/' + id).then(() => {
                refresh()
              })
            }
          })
        }}
        actionItems={[
          {
            text: 'Add New Booking',
            class: 'btn btn-sm btn-primary',
            icon: 'fa fa-golf-ball',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              if (itemIdForUpdate !== undefined) {
                setItemIdForUpdate('add')
              }
              setRefreshFunction(() => refresh)
            },
          },
        ]}
        header={bookingHeader}
      />
      {itemIdForUpdate !== '' ? (
        <BookingRequestEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onClose={() => {
            setItemIdForUpdate('')
          }}
          refresh={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default BookingRequestList
