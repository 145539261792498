/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../helpers'
import { User } from '../../../../app/modules/auth/core/_models'
import { meApi } from '../../../../app/modules/auth/core/_requests'

const SidebarFooter = () => {
  const [user, setUser] = useState<User>()
  const [token, setToken] = useState<string | undefined>(() => {
    return localStorage.getItem('@auth/token') ?? undefined
  })

  useEffect(() => {
    const fetchUser = async () => {
      const user = await meApi(token) // meApi'yi çağırarak kullanıcı bilgilerini alıyoruz

      if (!user) {
        setToken(undefined)
        return
      }
      setUser(user.data)
    }
    fetchUser()
  }, [])
  let hasValidRole = false
  user?.roles.map((role) => {
    if (role == '1' || role == '2') {
      hasValidRole = true
    } else {
      hasValidRole = false
    }
  })
  if (!hasValidRole) {
    return <></>
  }
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <a
        href='/support'
        rel='noopener noreferrer'
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss-='click'
        title='Support'
      >
        <span className='btn-label'>Support</span>
        <KTSVG path='/media/icons/duotune/general/gen005.svg' className='btn-icon svg-icon-2 m-0' />
      </a>
    </div>
  )
}

export { SidebarFooter }
