import axios from 'axios'
import { LoginParams, RequestResetPasswordParams, ResetPasswordParams } from './_models'

export function loginPostApi({ ...params }: LoginParams) {
  const data = {
    username: params.username,
    password: params.password,
  }

  return axios
    .post('/login', data, {
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    })
    .then((res) => res.data)
}

export function meApi(token?: string) {
  if (!token) {
    return Promise.resolve(null)
  }

  return axios
    .get('/auth/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .then((data) => {
      return data
    })
}

export function forgetPasswordPostAPI(email: string) {
  return axios.post('/forget-password', { email }).then((res) => res.data)
}

export function requestResetPasswordPostAPI(
  requestResetPasswordParams: RequestResetPasswordParams
) {
  const { code, ...restParams } = requestResetPasswordParams

  return axios
    .post('/forget-password/verify-otp', { ...restParams, code: code })
    .then((res) => res.data)
}

export function resetPasswordPostAPI(resetPasswordParams: ResetPasswordParams) {
  const { resetToken, passwordConfirmation, ...restParams } = resetPasswordParams

  return axios
    .post(`/reset-password`, {
      ...restParams,
      password: passwordConfirmation,
    })
    .then((res) => res.data)
}
