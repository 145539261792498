import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DocumentTitle from 'react-document-title'
import AreaTypeList from './components/AreaTypeList'

const settingsBreadcrumb: Array<PageLink> = [
  {
    title: 'Settings',
    path: '/settings',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SettingsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='area_types'
          element={
            <>
              <DocumentTitle title={process.env.REACT_APP_NAME + ' | Area Types'}></DocumentTitle>
              <PageTitle breadcrumbs={settingsBreadcrumb}>Area Types</PageTitle>
              <AreaTypeList />
            </>
          }
        />

        <Route index element={<Navigate to='/settings/area_types' />} />
      </Route>
    </Routes>
  )
}

export default SettingsPage
