import React, { useState, useEffect, useMemo } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

const BookingCalendar: React.FC = () => {
  const [events, setEvents] = useState<any[]>([])

  useEffect(() => {
    const dummyEvents = [
      {
        title: 'Event 1',
        start: '2024-08-09T10:00:00',
        end: '2024-08-09T12:00:00',
      },
      {
        title: 'Event 2',
        start: '2024-08-10T14:00:00',
        end: '2024-08-10T16:00:00',
      },
    ]

    setEvents(dummyEvents)
  }, [])

  const calendarSettings = useMemo(
    () => ({
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      views: {
        timeGridDay: {
          buttonText: 'Day',
          slotLabelInterval: '01:00',
          slotLabelFormat: {
            hour: 'numeric' as const,
            minute: '2-digit' as const,
            omitZeroMinute: false as const,
            meridiem: 'short' as const,
          },
          allDaySlot: false,
        },
        timeGridWeek: {
          buttonText: 'Week',
          slotLabelInterval: '01:00',
          slotLabelFormat: {
            hour: 'numeric' as const,
            minute: '2-digit' as const,
            omitZeroMinute: false as const,
            meridiem: 'short' as const,
          },
          allDaySlot: false,
        },
        dayGridMonth: {
          buttonText: 'Month',
          titleFormat: {
            year: 'numeric' as const,
            month: 'long' as const,
          },
          dayMaxEventRows: 2,
        },
      },
      height: 1400,
    }),
    []
  )

  return (
    <div className='container mt-5'>
      <div className='card shadow-sm'>
        <div className='card-body'>
          <h3 className='card-title text-center mb-4'>Booking Calendar</h3>
          <FullCalendar {...calendarSettings} events={events} />
        </div>
      </div>
    </div>
  )
}

export default BookingCalendar
