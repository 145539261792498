import React, { useState } from 'react'
import './scheduler.css'
import SchedulerToolbar from './SchedulerToolbar'
import SchedulerEventEditModal from './SchedulerEventEditModal'

const hours = Array.from({ length: 24 }, (_, i) => `${i}:00`)
const initialTrainers = ['Trainer A', 'Trainer B', 'Trainer C']
const initialBranches = ['Branch 1', 'Branch 2', 'Branch 3']
const initialLanes = ['Lane 1', 'Lane 2', 'Lane 3']

interface Event {
  id: number
  date: Date
  startTime: Date
  endTime: Date
  trainer: string
  branch: string
  area: string
  status: string
}

const initialEvents: Event[] = [
  {
    id: 1,
    date: new Date(),
    startTime: new Date(new Date().setHours(9, 0, 0)),
    endTime: new Date(new Date().setHours(11, 0, 0)),
    trainer: 'Trainer A',
    branch: 'Branch 1',
    area: 'Lane 1',
    status: 'Scheduled',
  },
  {
    id: 2,
    date: new Date(),
    startTime: new Date(new Date().setHours(13, 0, 0)),
    endTime: new Date(new Date().setHours(15, 0, 0)),
    trainer: 'Trainer C',
    branch: 'Branch 1',
    area: 'Lane 3',
    status: 'Scheduled',
  },
  {
    id: 3,
    date: new Date(),
    startTime: new Date(new Date().setHours(13, 0, 0)),
    endTime: new Date(new Date().setHours(15, 0, 0)),
    trainer: 'Trainer B',
    branch: 'Branch 1',
    area: 'Lane 3',
    status: 'Scheduled',
  },
]

const Scheduler: React.FC = () => {
  const [events, setEvents] = useState<Event[]>(initialEvents)
  const [showModal, setShowModal] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null)
  const [dragging, setDragging] = useState<{ trainer: string; start: number; end: number } | null>(
    null
  )
  const [startTime, setStartTime] = useState<Date | null>(null)
  const [endTime, setEndTime] = useState<Date | null>(null)

  const [selectedDate, setSelectedDate] = useState<Date | [Date, Date]>(new Date())
  const [selectedBranch, setSelectedBranch] = useState<string>('')
  const [selectedArea, setSelectedArea] = useState<string>('')
  const [selectedTrainer, setSelectedTrainer] = useState<string>('')

  const handleShow = (event?: Event | null) => {
    if (event) {
      setSelectedEvent(event)
      setStartTime(event.startTime)
      setEndTime(event.endTime)
    } else {
      setSelectedEvent(null)
      setStartTime(null)
      setEndTime(null)
    }
    setShowModal(true)
  }

  const handleClose = () => {
    setSelectedEvent(null)
    setStartTime(null)
    setEndTime(null)
    setShowModal(false)
  }

  const handleSave = (newEvent: Event) => {
    const { id, date, startTime, endTime, trainer, branch, area, status } = newEvent

    if (id) {
      setEvents(
        events.map((event) =>
          event.id === id
            ? { ...event, date, startTime, endTime, trainer, branch, area, status }
            : event
        )
      )
    } else {
      const id = new Date().getTime()
      setEvents([...events, { id, date, startTime, endTime, trainer, branch, area, status }])
    }
    handleClose()
  }

  const handleDelete = (id: number) => {
    setEvents(events.filter((event) => event.id !== id))
  }

  const handleCellMouseDown = (trainerName: string, hour: number) => {
    setDragging({ trainer: trainerName, start: hour, end: hour + 1 })
  }

  const handleCellMouseEnter = (hour: number) => {
    if (dragging) {
      setDragging((prev) => (prev ? { ...prev, end: hour + 1 } : null))
    }
  }

  const handleCellMouseUp = () => {
    if (dragging) {
      const { trainer, start, end } = dragging
      const newEvent = {
        id: 0,
        date: new Date(selectedDate instanceof Array ? selectedDate[0] : selectedDate),
        startTime: new Date(new Date().setHours(start)),
        endTime: new Date(new Date().setHours(end)),
        trainer,
        branch: 'Branch 1',
        area: 'Lane 1',
        status: 'Scheduled',
      }
      handleShow(newEvent)
      setDragging(null)
    }
  }

  const handleNextDay = () => {
    setSelectedDate(
      new Date(
        selectedDate instanceof Date
          ? selectedDate.setDate(selectedDate.getDate() + 1)
          : selectedDate[0].setDate(selectedDate[0].getDate() + 1)
      )
    )
  }

  const handlePrevDay = () => {
    setSelectedDate(
      new Date(
        selectedDate instanceof Date
          ? selectedDate.setDate(selectedDate.getDate() - 1)
          : selectedDate[0].setDate(selectedDate[0].getDate() - 1)
      )
    )
  }

  const handleToday = () => {
    setSelectedDate(new Date())
  }

  return (
    <div className='container-fluid p-4'>
      <SchedulerToolbar
        dateFilterType='single'
        selectedDate={selectedDate}
        selectedBranch={selectedBranch}
        selectedArea={selectedArea}
        selectedTrainer={selectedTrainer}
        trainerFilterShow={false}
        onDateChange={setSelectedDate}
        onBranchChange={setSelectedBranch}
        onAreaChange={setSelectedArea}
        onTrainerChange={setSelectedTrainer}
      />

      <div className='d-flex justify-content-between mb-3'>
        <div>
          <button className='btn btn-primary me-2' onClick={handlePrevDay}>
            Prev Day
          </button>
          <button className='btn btn-primary me-2' onClick={handleToday}>
            Now
          </button>
          <button className='btn btn-primary' onClick={handleNextDay}>
            Next Day
          </button>
        </div>
        <div className='text-center'>
          <h4>
            {new Date(
              selectedDate instanceof Array ? selectedDate[0] : selectedDate
            ).toDateString()}
          </h4>
        </div>
      </div>

      <div className='scheduler-container-custom'>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Trainers</th>
              {hours.map((hour, index) => (
                <th key={index} className='text-center'>
                  {hour}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {initialTrainers.map((trainerName, rowIndex) => (
              <tr key={rowIndex}>
                <td className='text-center scheduler-resource-name'>{trainerName}</td>
                {hours.map((_, columnIndex) => {
                  const isDragging =
                    dragging &&
                    dragging.trainer === trainerName &&
                    columnIndex >= dragging.start &&
                    columnIndex < dragging.end
                  const isOccupied = events.some(
                    (event) =>
                      event.trainer === trainerName &&
                      event.startTime.getHours() <= columnIndex &&
                      event.endTime.getHours() > columnIndex
                  )

                  return (
                    <td
                      key={columnIndex}
                      className={`position-relative scheduler-cell ${
                        isDragging ? 'range-selecting' : isOccupied ? 'scheduler-cell' : ''
                      }`}
                      onMouseDown={() => handleCellMouseDown(trainerName, columnIndex)}
                      onMouseEnter={() => handleCellMouseEnter(columnIndex)}
                      onMouseUp={handleCellMouseUp}
                    >
                      {events
                        .filter(
                          (event) =>
                            event.trainer === trainerName &&
                            event.startTime.getHours() <= columnIndex &&
                            event.endTime.getHours() > columnIndex
                        )
                        .map((event) => (
                          <div
                            key={event.id}
                            className='scheduler-event'
                            style={{
                              gridColumn: `${event.startTime.getHours() + 1} / span ${
                                event.endTime.getHours() - event.startTime.getHours()
                              }`,
                              height: '100%',
                            }}
                            onClick={() => handleShow(event)}
                          >
                            {event.branch} - {event.area}
                          </div>
                        ))}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && (
        <SchedulerEventEditModal
          showModal={showModal}
          handleClose={handleClose}
          date={selectedDate instanceof Array ? selectedDate[0] : selectedDate}
          startTime={startTime || new Date()}
          endTime={endTime || new Date()}
          trainer={selectedEvent?.trainer || 'Trainer A'}
          area={selectedEvent?.area || 'area 1'}
          branch={selectedEvent?.branch || 'Branch 1'}
          setDate={(date) => setSelectedDate(date)}
          setStartTime={(time) => setStartTime(time)}
          setEndTime={(time) => setEndTime(time)}
          setTrainer={(trainer) => setSelectedTrainer(trainer)}
          setArea={(lane) => setSelectedArea(lane)}
          setBranch={(branch) =>
            setSelectedEvent(selectedEvent ? { ...selectedEvent, branch } : null)
          }
          handleSave={() => {}}
          handleDelete={() => {}}
          hourOptions={hours}
          trainerOptions={initialTrainers}
          areaOptions={initialLanes}
          branchOptions={initialBranches}
        />
      )}
    </div>
  )
}

export default Scheduler
