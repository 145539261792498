export const userRoles = [
  { value: 'ROLE_USER', label: 'User' },
  { value: 'ROLE_PERSONAL_TRAINER', label: 'Personal Trainer' },
  { value: 'ROLE_PHYSIO', label: 'Physio' },
  { value: 'ROLE_COACH', label: 'Coach' },
  { value: 'ROLE_ADMIN', label: 'Admin' },
  { value: 'ROLE_SUPER_ADMIN', label: 'Super Admin' },
]

export const userStatus = [
  { value: 'Active', label: 'Active' },
  { value: 'Blocked', label: 'Blocked' },
  { value: 'Banned', label: 'Banned' },
]
