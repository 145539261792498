import React, { useState } from 'react'
import './schedulerWeekView.css'
import SchedulerToolbar from './SchedulerToolbar'
import SchedulerEventEditModal from './SchedulerEventEditModal'
import { format, startOfWeek } from 'date-fns'
import { addDays } from 'date-fns'

const hours = Array.from({ length: 24 }, (_, i) => `${i}:00`)
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

interface Event {
  id: number
  date: Date
  startTime: Date
  endTime: Date
  trainer: string
  branch: string
  area: string
  status: string
}

const initialEvents: Event[] = [
  {
    id: 1,
    date: new Date(),
    startTime: new Date(new Date().setHours(9, 0, 0, 0)),
    endTime: new Date(new Date().setHours(10, 0, 0, 0)),
    trainer: 'Trainer A',
    branch: 'Branch 1',
    area: 'area 1',
    status: 'Scheduled',
  },
  {
    id: 2,
    date: new Date(),
    startTime: new Date(new Date().setHours(12, 0, 0, 0)),
    endTime: new Date(new Date().setHours(13, 0, 0, 0)),
    trainer: 'Trainer B',
    branch: 'Branch 2',
    area: 'area 2',
    status: 'Scheduled',
  },
  {
    id: 3,
    date: new Date(),
    startTime: new Date(new Date().setHours(15, 0, 0, 0)),
    endTime: new Date(new Date().setHours(16, 0, 0, 0)),
    trainer: 'Trainer C',
    branch: 'Branch 3',
    area: 'area 3',
    status: 'Scheduled',
  },
]

const SchedulerWeekView: React.FC = () => {
  const [events, setEvents] = useState<Event[]>(initialEvents)
  const [showModal, setShowModal] = useState(false)
  const [title, setTitle] = useState('')
  const [startTime, setStartTime] = useState<Date | undefined>(undefined)
  const [endTime, setEndTime] = useState<Date | undefined>(undefined)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [selectedBranch, setSelectedBranch] = useState<string>('')
  const [selectedArea, setSelectedArea] = useState<string>('')
  const [selectedTrainer, setSelectedTrainer] = useState<string>('')
  const [currentWeekOffset, setCurrentWeekOffset] = useState(0)
  const [dragging, setDragging] = useState<{
    date: Date
    startTime: Date
    endTime: Date
  } | null>(null)

  const handleShow = () => setShowModal(true)
  const handleClose = () => {
    setTitle('')
    setStartTime(undefined)
    setEndTime(undefined)
    setDragging(null)
    setShowModal(false)
  }

  const handleSave = () => {
    if (title && startTime && endTime && selectedDate) {
      const id = new Date().getTime()
      const newEvent = {
        id,
        date: selectedDate,
        startTime,
        endTime,
        trainer: selectedTrainer,
        branch: 'Branch 1',
        area: selectedArea,
        status: 'Scheduled',
      }
      setEvents([...events, newEvent])
      handleClose()
    }
  }

  const handleDelete = (id: number) => {
    setEvents(events.filter((event) => event.id !== id))
  }

  const handleCellMouseDown = (date: Date, hour: number) => {
    setDragging({
      date,
      startTime: new Date(date.setHours(hour, 0, 0, 0)),
      endTime: new Date(date.setHours(hour + 1, 0, 0, 0)),
    })
  }

  const handleCellMouseEnter = (hour: number) => {
    if (dragging) {
      setDragging((prev) => ({
        ...prev!,
        endTime: new Date(prev!.date.setHours(hour + 1, 0, 0, 0)),
      }))
    }
  }

  const handleCellMouseUp = () => {
    if (dragging) {
      setStartTime(dragging.startTime)
      setEndTime(dragging.endTime)
      setSelectedDate(dragging.date)
      handleShow()
      setDragging(null)
    }
  }

  const handleNextWeek = () => {
    setCurrentWeekOffset(currentWeekOffset + 1)
  }

  const handlePrevWeek = () => {
    setCurrentWeekOffset(currentWeekOffset - 1)
  }

  const startOfWeekDate = startOfWeek(new Date(), { weekStartsOn: 1 })
  const weekDates = Array.from({ length: 7 }, (_, i) =>
    addDays(startOfWeekDate, i + currentWeekOffset * 7)
  )
  const formattedWeekDates = weekDates.map((date) => format(date, 'dd MMM'))
  const filteredEvents = events.filter((event) => {
    const matchesArea = selectedArea === '' || event.area === selectedArea
    const matchesTrainer = selectedTrainer === '' || event.trainer === selectedTrainer
    const matchesDate =
      !Array.isArray(selectedDate) || (event.date >= selectedDate && event.date <= selectedDate)

    return matchesArea && matchesTrainer && matchesDate
  })

  return (
    <>
      <div className='toolbar'>
        <SchedulerToolbar
          dateFilterType={'single'}
          selectedDate={selectedDate}
          selectedBranch={selectedBranch}
          selectedArea={selectedArea}
          selectedTrainer={selectedTrainer}
          trainerFilterShow={true}
          onDateChange={(date) => setSelectedDate(date as Date)}
          onBranchChange={(branch) => setSelectedBranch(branch)}
          onAreaChange={(area) => setSelectedArea(area)}
          onTrainerChange={(trainer) => setSelectedTrainer(trainer)}
        />
      </div>

      <div className='scheduler-container-custom'>
        <div className='toolbar'>
          <button className='btn btn-secondary' onClick={handlePrevWeek}>
            Prev Week
          </button>

          <button className='btn btn-secondary' onClick={handleNextWeek}>
            Next Week
          </button>
        </div>

        <div className='scheduler-week-table-container'>
          <table className='scheduler-week-table'>
            <thead>
              <tr>
                <th>Hours</th>
                {formattedWeekDates.map((day, index) => (
                  <th key={index} className='scheduler-resource-name'>
                    {day}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {hours.map((hour, rowIndex) => (
                <tr key={rowIndex}>
                  <td className='scheduler-week-hour'>{hour}</td>
                  {daysOfWeek.map((day, columnIndex) => {
                    const date = new Date()
                    date.setDate(
                      date.getDate() - date.getDay() + columnIndex + 1 + currentWeekOffset * 7
                    )

                    const isDragging =
                      dragging &&
                      dragging.date.getTime() === date.getTime() &&
                      rowIndex >= dragging.startTime.getHours() &&
                      rowIndex < dragging.endTime.getHours()
                    const isOccupied = filteredEvents.some(
                      (event) =>
                        event.date.getTime() === date.getTime() &&
                        event.startTime.getHours() <= rowIndex &&
                        event.endTime.getHours() > rowIndex
                    )

                    return (
                      <td
                        key={columnIndex}
                        className={`scheduler-cell ${
                          isDragging ? 'range-selecting' : isOccupied ? 'range-selected' : ''
                        }`}
                        onMouseDown={() => handleCellMouseDown(date, rowIndex)}
                        onMouseEnter={() => handleCellMouseEnter(rowIndex)}
                        onMouseUp={handleCellMouseUp}
                      >
                        {filteredEvents
                          .filter(
                            (event) =>
                              event.date.getTime() === date.getTime() &&
                              event.startTime.getHours() <= rowIndex &&
                              event.endTime.getHours() > rowIndex
                          )
                          .map((event) => (
                            <div
                              key={event.id}
                              className='scheduler-event'
                              style={{
                                gridRow: `${event.startTime.getHours() + 1} / span ${
                                  event.endTime.getHours() - event.startTime.getHours()
                                }`,
                                height: '100%',
                              }}
                            >
                              {event.trainer}
                            </div>
                          ))}
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <SchedulerEventEditModal
          showModal={showModal}
          handleClose={handleClose}
          date={selectedDate}
          startTime={startTime || new Date()}
          endTime={endTime || new Date()}
          trainer={selectedTrainer}
          area={selectedArea}
          branch={'Branch 1'}
          setDate={(date) => setSelectedDate(date)}
          setStartTime={(time) => setStartTime(time)}
          setEndTime={(time) => setEndTime(time)}
          setTrainer={(trainer) => setSelectedTrainer(trainer)}
          handleSave={handleSave}
          handleDelete={() => {}}
          hourOptions={hours}
          trainerOptions={['Trainer A', 'Trainer B', 'Trainer C']}
          areaOptions={['area 1', 'area 2', 'area 3']}
          branchOptions={['Branch 1', 'Branch 2', 'Branch 3']}
          setArea={function (area: string): void {
            throw new Error('Function not implemented.')
          }}
          setBranch={function (branch: string): void {
            throw new Error('Function not implemented.')
          }}
        />
      </div>
    </>
  )
}

export default SchedulerWeekView
